import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { OrganizationsService } from 'src/app/super-admin/services/organizations.service';
import { ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';

export enum ComponentMode {
  CREATE,
  UPDATE
}

@Component({
  selector: 'create-update-organization',
  templateUrl: './create-update-organization.component.html',
})
export class CreateUpdateOrganizationComponent {
  organizationName: string
  id: string
  createUpdateMode: ComponentMode
  createUpdatetitle: string = 'CreateOrganization'
  confirmTitle: string = 'ConfirmCreationTitle'
  confirmMessage: string = 'confirmOrganizationCreationMessage'

  constructor(public dialogRef: MatDialogRef<CreateUpdateOrganizationComponent>,
    private languageService: LanguageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private organizationsService: OrganizationsService) {
    if (data) {
      this.createUpdateMode = ComponentMode.UPDATE
      this.organizationName = data.name
      this.id = data.id
      this.createUpdatetitle = 'UpdateOrganization'
      this.confirmTitle = 'ConfirmUpdateTitle'
      this.confirmMessage = 'ConfirmOrganizationUpdateMessage'
    } else {
      this.createUpdateMode = ComponentMode.CREATE
    }
  }

  openConfirmDialog() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25vw',
      data: {
        title: `${this.languageService.translateSync(this.confirmTitle)}`,
        message: `${this.languageService.translateSync(this.confirmMessage)} '${this.createUpdateMode === ComponentMode.CREATE ? this.organizationName : this.id}'?`
      }
    })
    dialogRef.afterClosed().subscribe(async confirmResult => {
      if (!confirmResult) {
        return
      }
      this.save()
    })
  }

  async save() {
    if (this.createUpdateMode === ComponentMode.CREATE) {
      await this.organizationsService.createOrganization(this.organizationName.trim())
      this.snackbarService.openSnackBar(2000, `${this.languageService.translateSync('organizationSuccessfullyCreated')}`)
    } else if (this.createUpdateMode === ComponentMode.UPDATE) {
      await this.organizationsService.updateOrganization({ id: this.id, name: this.organizationName.trim() })
      this.snackbarService.openSnackBar(2000, `${this.languageService.translateSync('organizationSuccessfullyUpdated')}`)
    }
    this.dialogRef.close({ isOrganizationUpdateOrCreated: true })
  }
}
