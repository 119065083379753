<div class="padding">


    <div class="title">
        <p style="margin-bottom: 0;">{{'CustomersManagement'|translate}}</p>
        <small>{{'ManagesCustomerDetails' |translate}}</small>
    </div>

    <div class="main-card" style="background-color: #fff; padding: 16px;">
        <div style="margin-bottom: 16px;margin-left: 5px; display: flex;">
            <a routerLink="/super/home"><mat-icon>home</mat-icon></a>
            <mat-icon class="chevron-separator">chevron_right</mat-icon>
            <a routerLink="/super/organizations">{{'Organizations'|translate}}</a>
            <mat-icon class="chevron-separator">chevron_right</mat-icon>
            <a>{{organizationId}}</a>
            <mat-icon class="chevron-separator">chevron_right</mat-icon>
            <a routerLink="/super/organizations/companies" [queryParams]="{ organization_id : organizationId }">
                {{'Companies'|translate}}</a>
            <mat-icon class="chevron-separator">chevron_right</mat-icon>
            <span>{{companyId}}</span>
            <mat-icon class="chevron-separator">chevron_right</mat-icon>
            <span><b>{{'CustomerManagement'|translate}}</b></span>
        </div>
        <customer-list></customer-list>
    </div>

    <!--div style='padding-right: 15px;padding-bottom: 20px;padding-left: 5px;'>
        <button matTooltip="{{'Refresh'|translate}}" mat-mini-fab color="primary" (click)="refresh()">
            <mat-icon>refresh</mat-icon>
        </button>
        <span style='padding: 10px;'></span>

        <button mat-mini-fab matTooltip="{{ 'ADD' | translate }}" color="primary" (click)="add()">
            <mat-icon>add</mat-icon>
        </button>
    </!--div-->

</div>