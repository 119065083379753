import { Expose, Type } from 'class-transformer'
import { IsArray, IsBoolean, IsDate, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { DateType } from '../customDecorators'
import { ProductCategory } from './product'

export class ProductImageCatalog {
  @Expose() @IsString() id!: string
  @Expose() @IsString() mc1ProductId!: string
  @Expose() @IsString() name!: string
  @Expose() @IsOptional() @IsNumber() height?: number
  @Expose() @IsOptional() @IsNumber() width?: number
  @Expose() @IsOptional() @IsNumber() depth?: number
  @Expose() @IsBoolean() isActive!: boolean
  @Expose() @DateType() @IsDate() lastUpdate!: Date
  @Expose() @IsString() thumbnailUrl!: string
  isPrimary?: boolean
}

export class ImageCatalogDimensions {
  @Expose() @IsNumber() @IsOptional() width?: number
  @Expose() @IsNumber() @IsOptional() height?: number
  @Expose() @IsNumber() @IsOptional() depth?: number

}
export class BaseImageCatalog {
  @Expose() @IsString() id!: string
  @Expose() @IsString() name!: string
  @Expose() @DateType() @IsDate() lastUpdate!: Date
  @Expose() @IsString() thumbnailUrl!: string
  @Expose() @Type(() => ImageCatalogDimensions) @ValidateNested() @IsOptional() dimensions?: ImageCatalogDimensions
}

export class Product {
  @Expose() @IsString() mc1Id!: string
  @Expose() @IsBoolean() isCompetitors!: boolean
  @Expose() @IsBoolean() @IsOptional() inactive?: boolean
  @Expose() @IsString() name!: string
  @Expose() @IsString() @IsOptional() ean?: string
  @Expose() @DateType() @IsDate() updatedAt!: Date
  @Expose() @Type(() => String) @IsArray() @IsString({ each: true }) categoriesNames!: string[]
  @Expose() @Type(() => String) @IsArray() @IsString({ each: true }) brandNames!: string[]
  @Expose() @Type(() => ProductCategory) @ValidateNested() category!: ProductCategory
  @Expose() @IsString() family?: string
  @Expose() @IsNumber() width?: number
  @Expose() @IsNumber() height?: number
  @Expose() @IsString() thumbnailUrl?: string
}