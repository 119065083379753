<h2 mat-dialog-title>{{createUpdatetitle|translate}}</h2>
<mat-dialog-content>
    <mat-form-field>
        <mat-label>{{'Organization Name'|translate}}</mat-label>
        <input matInput [(ngModel)]="organizationName" required>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close matTooltip="{{'CANCEL'|translate}}">
        <mat-icon color="primary">cancel</mat-icon>
    </button>
    <button mat-button (click)="openConfirmDialog()" matTooltip="{{'SaveChanges'|translate}}">
        <mat-icon color="primary">save</mat-icon>
    </button>
</mat-dialog-actions>