import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { firebase } from 'firebaseui-angular';
import jwt_decode from 'jwt-decode';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { StandaloneNavbarService } from 'src/app/shared/services/standalone.navbar.service';
import { TrainingsService } from '../admin/services/trainings.service';
import { Dal } from '../dal/dal';
import { ConfigService } from '../services/config.service';
import { CookieService } from '../services/cookie.service';
import { MemoryStorageService } from '../services/memory-storage.service';
import { AutoDestroy } from '../shared/base-directives/auto-destroy';
import { RunInOrderGuard } from '../shared/guards/run-in-order.guard';
import { AuthService } from '../shared/services/auth.service';
import { AvailableScreensService } from '../shared/services/available-screens.service';
import { getCompanyType } from '../shared/services/companyType.service';
import { LanguageService } from '../shared/services/language.service';
import { SettingsService } from '../shared/services/settings.service';
import { Location } from '@angular/common';
import { DashboardsCommunicatorService } from '../services/dashboards-communicator.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends AutoDestroy implements OnInit {
  isRtl = false

  companyType = getCompanyType()
  _logged: boolean = false
  showStandaloneNav: boolean = true

  isShowSpinner = true
  checkSessionInterval: ReturnType<typeof setInterval>
  loggedSubscribe: Subscription

  constructor(
    private languageService: LanguageService,
    private router: Router,
    public dialog: MatDialog,
    public runInOrderGuard: RunInOrderGuard,
    private configService: ConfigService,
    private aAuth: AngularFireAuth,
    private memoryStorageService: MemoryStorageService,
    public authService: AuthService,
    private trainingService: TrainingsService,
    private availableScreensService: AvailableScreensService,
    private settingsService: SettingsService,
    private dal: Dal,
    private standaloneNavbarService: StandaloneNavbarService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private location: Location,
    private dashboardsCommunicatorService: DashboardsCommunicatorService
  ) {
    super()

    this.calcRtl()

    this.subscriptions.push(languageService.onLanguageChanged.subscribe(() => this.calcRtl()))

    this.subscriptions.push(router.events.subscribe(() => { })) // verify this behavior

    if (getCompanyType() === 'Standalone') {
      this.checkSessionInterval = setInterval(() => {
        if (this.authService.isLogged() == false) {
          return
        }
        this.authService.checkSession()
      }, 1000 * 60 * 30)
    }
  }

  ngOnInit() {
    this.onActivate()
    if (this.configService.config.firebase?.authDomain === "ire-stand-alone-dev.firebaseapp.com") {
      this.languageService.checkMissingTranslations()
    }

    this.standaloneNavbarService.showNavbar.subscribe((show) => {
      this.showStandaloneNav = show
    })

    // this.subscriptions.push(this.languageService.onLanguageChanged.subscribe(() => {
    //   this.calcRtl()
    //   this.initializeApp()
    // }))
    this.calcRtl()
    this.initializeApp()
    this.authService.$logged.subscribe((logged) => {
      this._logged = logged
    })
    const token = this.cookieService.getCookie('__session')
    this.dashboardsCommunicatorService.initSession()
    this.navigateToReturnUrl(token)
  }

  private navigateToReturnUrl(token: unknown) {
    let returnUrl

    if (!token || typeof token != 'string') {
      return
    }

    const tokenDecoded = jwt_decode(token)
    const role = tokenDecoded['role']

    if (role === 'super_admin' && !this.location.path().includes('/super')) {
      returnUrl = '/super'
      this.router.navigateByUrl(returnUrl)
    } else if (role === 'organization_admin' && !this.location.path().includes('/organizationAdmin')) {
      returnUrl = '/organizationAdmin'
      this.router.navigateByUrl(returnUrl)
    }
  }

  async initializeApp() {
    try {
      // checks if there is a token in the url and saves it in the memoryStorageService
      const t = new URLSearchParams(window.location.search).get('token');
      if (t) {
        this.memoryStorageService.token = t
        this.router.navigate([window.location.pathname], {
          queryParams: {
            'token': null,
          },
          queryParamsHandling: 'merge'
        })
      }
      await this.authService.checkSession()
      this.aAuth.authState.subscribe(() => {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)
      })
      this.loggedSubscribe = this.authService.$logged.pipe(
        filter((logged) => logged === true),
        take(1)
      ).subscribe(() => {
        this.getStartInfo()
      })
    } catch (e) {
      this.isShowSpinner = false
      console.error(e)
    }
  }

  calcRtl() {
    this.isRtl = this.languageService.translateSync('IsRtl') === 'true'
  }

  onActivate() {
    if (window.location.href.includes("admin") || window.location.href.includes("super")) {
      this.isShowSpinner = false
      this.showStandaloneNav = false
    } else if (window.location.href.includes("login")) {
      this.isShowSpinner = false
      this.showStandaloneNav = false
    } else {
      this.showStandaloneNav = true
      this.isShowSpinner = true
    }
  }

  async getStartInfo() {
    try {
      const settings = await this.settingsService.getSettings()

      if (settings?.mapsKey)
        this.memoryStorageService.mapsKey = settings.mapsKey
      
      const userSettings = this.companyType === 'Standalone' ? await this.dal.getUserInfo() : null
      const language = userSettings !== null ? userSettings.language : settings?.language
      this.languageService.setLanguage(null, language)
      this.availableScreensService.activateAvailabeleScreens()
      await this.trainingService.loadContractTraining(true)
    } catch (e) {
      // for super admin, get start info returns error
      this.languageService.setLanguage(null, "")
      this.isShowSpinner = false
      console.error(e)
    }
  }

  ngOnDestroy(): void {
    this.loggedSubscribe.unsubscribe()
    clearInterval(this.checkSessionInterval)
  }
}
