import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Dal } from 'src/app/dal/dal';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import StandaloneNavbarItemModel from 'src/app/shared/models/standalone-navbar-item.model';
import { GetUserInfoResponse } from 'src/app/shared/api-structures/admin/user';
import { AuthService } from 'src/app/shared/services/auth.service';
import { StandaloneNavbarService } from 'src/app/shared/services/standalone.navbar.service';

@Component({
  selector: 'super-admin-sidenav',
  templateUrl: './super-sidenav.component.html',
  styleUrls: ['./super-sidenav.component.scss']
})
export class SuperSidenavComponent extends AutoDestroy {
  isShowNav = false
  userInfo: GetUserInfoResponse | null
  menuItems: StandaloneNavbarItemModel[];

  private getDefaultMenuItems(): StandaloneNavbarItemModel[] {
    return [
      {
        displayName: "Home",
        iconName: "home",
        route: "/super/",
        permission: 'Home',
        children: [],
        order: 1,
        pageIdentifier: null,
      },
      {
        displayName: "Organizations",
        iconName: "business",
        route: "/super/organizations",
        permission: 'Organizations',
        children: [],
        order: 2,
        pageIdentifier: null,
      },
      {
        displayName: "Settings",
        iconName: "settings",
        route: "/super/settings",
        permission: 'Settings',
        children: [],
        order: 3,
        pageIdentifier: null,
      },
      {
        displayName: "User Management",
        iconName: "peopleAlt",
        route: "/super/userManagement",
        permission: 'UserManagement',
        children: [],
        order: 4,
        pageIdentifier: null,
      },
      {
        displayName: "Dashboards",
        iconName: "assessment",
        route: "/super/dashboards",
        permission: 'Dashboards',
        children: [],
        order: 5,
        pageIdentifier: null,
      },
      {
        displayName: "API Keys",
        iconName: "key",
        route: "/super/apiKeys",
        permission: 'ApiKeys',
        children: [],
        order: 6,
        pageIdentifier: null,
      }
    ];
  }


  constructor(private dal: Dal,
    private router: Router,
    private authService: AuthService,
    private standaloneNavbarService: StandaloneNavbarService,) {
    super()
    this.subscriptions.push(router.events.subscribe(v => this.isShowNav = false))

    this.dal.getUserInfo().then(userInfo => {
      this.userInfo = userInfo
    })

    this.menuItems = this.getDefaultMenuItems();
  }

  async logout() {
    this.standaloneNavbarService.setShowNavbar(false)
    await this.authService.logOut()
    window.location.reload()
  }
}
