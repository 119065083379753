import { uniqueId } from "underscore"
import { Rect } from "./Rect"
import { BuilderItem, Pos } from "./planogram-builder.component"
import { Area, Item } from "src/app/shared/api-structures/misc/planogramStructure"

export function createTempId() {
    return 'a' + uniqueId()
}

export function roundNumber(num: number) {
    return Number(num?.toFixed(2))
}

export function isRectOutsideArea(rect: Rect, area: BuilderItem,) {
    return rect.x < area.rect.x
        || roundNumber(rect.x + rect.width) > roundNumber(area.rect.x + area.rect.width)
        || rect.y < area.rect.y
        || roundNumber(rect.y + rect.height) > roundNumber(area.rect.y + area.rect.height)
}

export function findOverlapItems(rect: Rect, items: BuilderItem[]) {
    return items.filter(v => {

        return rect.x < roundNumber(v.rect.x + v.rect.width) &&
            roundNumber(rect.x + rect.width) > v.rect.x &&
            rect.y < roundNumber(v.rect.y + v.rect.height) &&
            roundNumber(rect.y + rect.height) > v.rect.y
    })
}

export function getNearRect(rect: Rect, overlapRect: Rect) {
    if (rect.x + rect.width < overlapRect.x + overlapRect.width) {
        return new Rect(overlapRect.x - rect.width - 0.001, rect.y, rect.width, rect.height)
    } else {
        return new Rect(overlapRect.x + overlapRect.width + 0.001, rect.y, rect.width, rect.height)
    }
}

export function isAboveItem(items: BuilderItem[], pos: Pos) {
    const itemsAbove = items.filter(v => {
        return pos.y < v.rect.y && pos.x > v.rect.x && pos.x < roundNumber(v.rect.x + v.rect.width)
    }).sort((a, b) => (a.rect.y > b.rect.y ? 1 : -1))
    return itemsAbove[0]
}

export function calcRoomAboveItem(item: BuilderItem, area: BuilderItem) {
    return roundNumber(area.rect.height - item.rect.height)
}

export function getMidItemPos(item: BuilderItem, quantity = 1) {
    return { x: item.rect.x + item.rect.width / quantity / 2, y: item.rect.y + item.rect.height / 2 }
}

export function prepareStructureItem(item: BuilderItem, area: BuilderItem) {
    const rect = new Rect(item.rect.x - area.rect.x, item.rect.y - area.rect.y, item.rect.width, item.rect.height)
    let itemData: Item = {
        absolutePoint: item.rect,
        rect: rect,
    }
    if (item.type === 'product') {
        itemData.itemType = 'product'
        itemData.productId = item.data.id
        itemData.imagesCatalogId = item.data.catalogId
        itemData.imgUrl = item.data.thumbnailUrl
        itemData.name = item.data.name
        itemData.isTrained = item.data.isTrained
        itemData.lastUpdadeCatalog = item.data.lastUpdateCatalog
    } else if (item.type === 'category') {
        itemData.itemType = 'category'
        itemData.categoryId = item.data.id
        itemData.name = item.data.name
        itemData.verticalText = item.data.verticalText
    } else if (item.type === 'space') {
        itemData.itemType = 'space'
    } else if (item.type === 'brand') {
        itemData.itemType = 'brand'
        itemData.brandId = item.data.id
        itemData.name = item.data.name
        itemData.verticalText = item.data.verticalText
    }
    return itemData
}

export function prepareStructureArea(area: BuilderItem, items: BuilderItem[]) {
    if (area.type !== 'area' && area.type !== 'seperator') {
        throw new Error('Invalid area type')
    }
    const areaData: Area = {
        areaType: area.data.areaType,
        rect: area.rect,
        items: items.filter(v => v.area === area.id).map(v => prepareStructureItem(v, area))
    }

    return areaData
}
