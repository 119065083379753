import { RequestAccountDeletionComponent } from './shared/components/request-account-deletion/request-account-deletion.component';
import { DatePipe, NgOptimizedImage, PRECONNECT_CHECK_BLOCKLIST } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AdminModule } from './admin/admin.module';
import { CommunicatorModule } from './communicator/communicator.module';
import { SnackbarComponent, SnackbarService } from './services/snackbar.service';
import { UtilitiesService } from './services/utilities.service';
import { DataTransformer } from './shared/api-structures/dataTransformer';
import { NetworkStatus2 } from './shared/components/network-status2';
import { PrimengModule } from './shared/modules/primeng.module';
import { ApiTrackerService } from './shared/services/api.tracker.service';
import { CustomPaginatorService } from './shared/services/custom-paginator.service';
import { DataTransformerService } from './shared/services/data-transformer.service';
import { LanguageService } from './shared/services/language.service';
import { SuperAdminModule } from './super-admin/super-admin.module';
import { AppComponent } from './views/app.component';
import { LoginComponent } from './shared/components/auth/login/login.component';
import { ConfirmDialogComponent } from './views/general/confirm-dialog/confirm-dialog.component';
import { InformationDialogComponent } from './views/general/information-dialog/information-dialog.component';
import { CopyPlanogramDialogComponent } from './views/planograms/copy-planogram-dialog/copy-planogram-dialog.component';
import { PlanogramNewDialogComponent } from './views/planograms/planogram-new-dialog/planogram-new-dialog.component';
import { PlanogramPlanningComponent } from './views/planograms/planogram-planning/planogram-planning/planogram-planning.component';
import { PalanogrmImageDialog, PlanogramsComponent } from './views/planograms/planograms-list/planograms.component';
import { ProductCatalogsComponent } from './views/products-catalogs/product-catalogs/product-catalogs.component';
import { ProductsBatchUploadComponent } from './views/products-catalogs/products-batch-upload/products-batch-upload.component';
import { ProductsComponent } from './views/products-catalogs/products-list/products.component';
import { TabsComponent } from './views/tabs/tabs.component';
import { UnapprovedComponent } from './views/unapproved/unapproved-list/unapproved.component';
import { UnapprovedDialogComponent } from './views/unapproved/unapproved-images/unapproved-images.component';
import { UplaodStatusComponent } from './views/products-catalogs/products-batch-upload/uplaod-status/uplaod-status.component';
import { ConfigService, configFactory } from './services/config.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EquipmentCreatePlanogramComponent } from './views/planograms/planogram-planning/equipment-create-planogram/equipment-create-planogram.component';
// import { ProductCreatePlanogramComponent } from './views/planograms/planogram-planning/product-create-planogram/product-create-planogram.component';
import { AuthGuard } from './shared/authGuard';
import { RunInOrderGuard } from './shared/guards/run-in-order.guard';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ProductNewDialogComponent } from './views/products-catalogs/product-new-dialog/product-new-dialog.component';
import { CreateEquipmentDialogComponent } from './views/planograms/create-equipment-dialog/create-equipment-dialog.component';
import { SharedModule } from './shared/shared.module';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ScrollingModule } from '@angular/cdk/scrolling';

import { PlanogramAssociationsComponent } from './views/planograms/planogram-associations/planogram-associations.component';
import { InsideViewportDirective } from './views/unapproved/unapproved-images/inside-viewport.directive';
import { OverlayAdjustmentsComponent } from './standalone-components/overlay-adjustments/overlay-adjustments.component';
import { OrganizationModule } from './organization-admin/organization-admin.module';
import { ProductFilterOptions } from './views/products-catalogs/products-list/product-filter/products-filter-options.component';
import { MatSliderModule } from '@angular/material/slider';
import { UnapprovedListFiltersComponent } from './views/unapproved/unapproved-list/unapproved-list-filters.component';
import { NoAuthGuard } from './shared/noAuthGuard';
import { CallbackFilterPipe } from './pipes/callback-filter.pipe';
import { MaterielModule } from './shared/modules/materiel.module';
import { ComplianceReportService } from './shared/services/complianceReport.service';
import { UnapprovedCatalogsComponent } from './views/unapproved/unapproved-catalogs/unapproved-catalogs.component';
import { EquipmentsComponent } from './views/equipments/equipments.component';
import { FilterUnapprovedImageDialog } from './views/unapproved/unapproved-images/filter-unapproved-image-dialog';
import { CreatePasswordComponent } from './shared/components/create-password/create-password.component';
import { EquipmentService } from './super-admin/services/equipment.service';
import { EquipmentsFilterOptions } from './views/equipments/equipments-filter-options.component';
import { ProductsFilterOptions } from './views/planograms/planogram-planning/planogram-planning/products-filter-options.component';
import { HomeComponent } from './views/home/home.component';
import { ClusterService } from './shared/services/cluster.service';
import { ProductsPlanogramsDialogComponent } from './views/products-catalogs/products-planograms-dialog/products-planograms-dialog.component';
import { LoginExtrenalComponent } from './components/login-extrenal/login-extrenal.component';
import { DefaultComponent } from './views/default/default.component';
import { ChatCpgComponent } from './views/chat-cpg/chat-cpg.component';
import { AddEditProductComponent } from './views/products-catalogs/add-edit-product/add-edit-product.component';
import { ReportsComponent } from './views/reports/reports.component';
import { StandaloneNavbarComponent } from './views/standalone-navbar/standalone-navbar.component';
import { getCompanyType } from './shared/services/companyType.service';
import { UserSettingsComponent } from './views/user-settings/user-settings.component';
import { PlanogramInfoComponent } from './views/planograms/planogram-info/planogram-info.component';
import { PlanogramBuilderComponent } from './views/planograms/planogram-planning/planogram-builder/planogram-builder.component';
import { HealthCheckComponent } from './views/health-check/health-check.component';
import { NotificationCardComponent } from './views/health-check/notification-card/notification-card.component';
import { FilterBySeverityPipe } from './pipes/filter-by-type.pipe';
import { HealthCheckModalComponent } from './views/health-check/health-check-modal/health-check-modal.component';
import { NotificationMenuComponent } from './views/standalone-navbar/notification-menu/notification-menu.component';
import { CapitalizeFirstPipe } from './shared/pipes/capitalize-first.pipe';
import { ImportFromImageDialogComponent } from './views/planograms/import-from-image-dialog/import-from-image-dialog.component';

function HttpLoaderFactory(http: HttpClient) { return new TranslateHttpLoader(http) }

const companyType = getCompanyType()

const appRoutes: Routes = [
    { canActivate: [NoAuthGuard], path: 'login', component: LoginComponent },
    { path: 'loginExtrenal', component: LoginExtrenalComponent },
    { canActivate: [AuthGuard], path: '', component: DefaultComponent },
    { canActivate: [RunInOrderGuard], path: 'home', component: companyType === 'Standalone' ? HomeComponent : TabsComponent, data: { accessRole: 'Home' } },
    { canActivate: [RunInOrderGuard], path: 'product-catalogs', component: companyType === 'Standalone' ? ProductCatalogsComponent : TabsComponent, data: { accessRole: 'Products' } },
    { canActivate: [RunInOrderGuard], path: 'products', component: companyType === 'Standalone' ? ProductsComponent : TabsComponent, data: { accessRole: 'Products' } },
    { canActivate: [RunInOrderGuard], path: 'equipments', component: companyType === 'Standalone' ? EquipmentsComponent : TabsComponent, data: { accessRole: 'Planograms' } },
    { canActivate: [RunInOrderGuard], path: 'planograms', component: companyType === 'Standalone' ? PlanogramsComponent : TabsComponent, data: { accessRole: 'Planograms' } },
    { canActivate: [RunInOrderGuard], path: 'unapproved', component: companyType === 'Standalone' ? UnapprovedComponent : TabsComponent, data: { accessRole: 'ImagesReview' } },
    { canActivate: [RunInOrderGuard], path: 'unapproved-catalogs', component: UnapprovedCatalogsComponent, data: { accessRole: 'ImagesReview' } },
    { canActivate: [RunInOrderGuard], path: 'image-review', component: UnapprovedDialogComponent, data: { accessRole: 'ImagesReview' } },
    { canActivate: [RunInOrderGuard], path: 'dashboard', component: companyType === 'Standalone' ? DashboardComponent : TabsComponent, data: { accessRole: 'Dashboard' } },
    { canActivate: [RunInOrderGuard], path: 'dashboard-accuracy', component: companyType === 'Standalone' ? DashboardComponent : TabsComponent, data: { accessRole: 'Dashboard' } },
    { canActivate: [RunInOrderGuard], path: 'dashboard-audit', component: companyType === 'Standalone' ? DashboardComponent : TabsComponent, data: { accessRole: 'Dashboard' } },
    { canActivate: [RunInOrderGuard], path: 'dashboard-compliance', component: companyType === 'Standalone' ? DashboardComponent : TabsComponent, data: { accessRole: 'Dashboard' } },
    { canActivate: [RunInOrderGuard], path: 'dashboard-analysis', component: companyType === 'Standalone' ? DashboardComponent : TabsComponent, data: { accessRole: 'Dashboard' } },
    { canActivate: [RunInOrderGuard], path: 'dashboard-precision', component: companyType === 'Standalone' ? DashboardComponent : TabsComponent, data: { accessRole: 'Dashboard' } },
    { canActivate: [RunInOrderGuard], path: 'dashboard-price', component: companyType === 'Standalone' ? DashboardComponent : TabsComponent, data: { accessRole: 'Dashboard' } },
    { canActivate: [RunInOrderGuard], path: 'dashboard-usage-report', component: companyType === 'Standalone' ? DashboardComponent : TabsComponent, data: { accessRole: 'Dashboard' } },
    { canActivate: [RunInOrderGuard], path: 'dashboard-heatmap', component: companyType === 'Standalone' ? DashboardComponent : TabsComponent, data: { accessRole: 'Dashboard' } },
    { canActivate: [RunInOrderGuard], path: 'dashboard-reports', component: companyType === 'Standalone' ? DashboardComponent : TabsComponent, data: { accessRole: 'Dashboard' } },
    { canActivate: [RunInOrderGuard], path: 'dashboard-monitoring', component: companyType === 'Standalone' ? DashboardComponent : TabsComponent, data: { accessRole: 'Dashboard' } },
    { canActivate: [RunInOrderGuard], path: 'mobileReports', component: companyType === 'Standalone' ? ReportsComponent : TabsComponent, data: { accessRole: 'Reports' } },
    { canActivate: [RunInOrderGuard], path: 'chatcpg', component: companyType === 'Standalone' ? ChatCpgComponent : TabsComponent, data: { accessRole: 'ChatCpg' } },
    { canActivate: [RunInOrderGuard], path: 'user-settings', component: UserSettingsComponent, data: { accessRole: 'Home' } },
    { canActivate: [RunInOrderGuard], path: 'planogram-planning', component: PlanogramPlanningComponent, data: { accessRole: 'Planograms' } },
    { canActivate: [RunInOrderGuard], path: 'planogram-info', component: PlanogramInfoComponent, data: { accessRole: 'Planograms' } },
    { canActivate: [RunInOrderGuard], path: 'manage-products', component: AddEditProductComponent, data: { accessRole: 'Products' } },
    { canActivate: [RunInOrderGuard], path: 'health-check', component: HealthCheckComponent, data: { accessRole: 'Home' } },
    { path: 'overlay_adjustments/:reportId', component: OverlayAdjustmentsComponent, data: { accessRole: 'Reports' } },
    //
    // { path: 'compliance-report/:reportId', component: ComplianceReportComponent },
    { path: 'product-catalogs-without-tabs', component: ProductsComponent, data: { accessRole: 'Products' } },
    { path: 'planograms-without-tabs', component: PlanogramsComponent, data: { accessRole: 'Planograms' } },
    { path: 'unapproved-without-tabs', component: UnapprovedComponent, data: { accessRole: 'ImagesReview' } },
    { path: 'dashboard-without-tabs', component: DashboardComponent, data: { accessRole: 'Planograms' } },
    { path: 'request-account-deletion', component: RequestAccountDeletionComponent },
    { path: 'createpassword', component: CreatePasswordComponent },
    { path: '**', redirectTo: '' }
];

@NgModule({
    declarations: [
        PlanogramBuilderComponent,
        NetworkStatus2,
        AppComponent,
        ProductCatalogsComponent,
        SnackbarComponent,
        PlanogramPlanningComponent,
        PlanogramsComponent,
        ProductsComponent,
        PalanogrmImageDialog,
        TabsComponent,
        ConfirmDialogComponent,
        InformationDialogComponent,
        PlanogramNewDialogComponent,
        CreateEquipmentDialogComponent,
        CopyPlanogramDialogComponent,
        UnapprovedComponent,
        UnapprovedDialogComponent,
        ProductsBatchUploadComponent,
        UplaodStatusComponent,
        ProductNewDialogComponent,
        DashboardComponent,
        EquipmentCreatePlanogramComponent,
        // ProductCreatePlanogramComponent,
        PlanogramAssociationsComponent,
        InsideViewportDirective,
        ReportsComponent,
        ProductFilterOptions,
        UnapprovedListFiltersComponent,
        UnapprovedCatalogsComponent,
        EquipmentsComponent,
        FilterUnapprovedImageDialog,
        EquipmentsFilterOptions,
        ProductsFilterOptions,
        HomeComponent,
        ProductsPlanogramsDialogComponent,
        AddEditProductComponent,
        LoginExtrenalComponent,
        DefaultComponent,
        ChatCpgComponent,
        ReportsComponent,
        StandaloneNavbarComponent,
        UserSettingsComponent,
        PlanogramInfoComponent,
        PlanogramBuilderComponent,
        HealthCheckComponent,
        NotificationCardComponent,
        FilterBySeverityPipe,
        HealthCheckModalComponent,
        NotificationMenuComponent,
        CapitalizeFirstPipe,
        ImportFromImageDialogComponent
    ],
    imports: [
        AdminModule,
        SuperAdminModule,
        OrganizationModule,
        CommunicatorModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes),
        FormsModule,
        ReactiveFormsModule,
        PrimengModule,
        DragDropModule,
        InfiniteScrollModule,
        MaterielModule,
        SharedModule,
        ScrollingModule,
        MatSliderModule,
        CallbackFilterPipe,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgOptimizedImage
    ],
    providers: [
        SnackbarService,
        UtilitiesService,
        EquipmentService,
        DatePipe,
        // ProductCreatePlanogramComponent,
        AppComponent,
        ClusterService,
        ComplianceReportService,
        { provide: MatPaginatorIntl, useClass: CustomPaginatorService, },
        { provide: DataTransformer, useClass: DataTransformerService },
        {
            provide: APP_INITIALIZER,
            useFactory: configFactory,
            deps: [ConfigService],
            multi: true,
        },
        { provide: PRECONNECT_CHECK_BLOCKLIST, useValue: 'https://storage.googleapis.com' }
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(languageService: LanguageService, apiTrackerService: ApiTrackerService) {
        apiTrackerService.init()
    }
}
