<div style="max-width: 543px;">
    <h2 style="margin-bottom: 0;" mat-dialog-title>{{'CreateApiKey'|translate}}</h2>
    <span style="margin-bottom: 24px;">{{'CreateApiKeyDetails'|translate}}</span>
    <mat-dialog-content>

        <mat-form-field>
            <mat-label>{{'Name'|translate}}</mat-label>
            <input [placeholder]="'EnterAPIKeyName'|translate" matInput [(ngModel)]="name" required>
        </mat-form-field>

        <div class="scrollbar" style="border: 2px solid #E3E4EB; margin: 1px; padding: 16px; overflow: hidden;">

            <mat-form-field>
                <mat-label>{{'Organization'|translate}}</mat-label>
                <mat-select [placeholder]="'SelectOrganization'|translate" [(value)]="selectedOrganizationId" (selectionChange)="onOrganizationSelected()">
                    <mat-option *ngFor="let org of orgs" [value]="org.organization">{{org.organization}}</mat-option>
                </mat-select>
                <mat-icon matSuffix *ngIf="isLoadingContexts">
                    <i class="fa fa-spinner fa-spin"></i>
                </mat-icon>
            </mat-form-field>


            <mat-form-field>
                <mat-label>{{'Enviroment'|translate}}</mat-label>
                <mat-select [placeholder]="'SelectEnvironment'|translate" [(value)]="selectedEnvironmentId" [disabled]="!selectedOrganizationId">
                    <mat-option *ngFor="let env of envs" [value]="env">{{env}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'Company'|translate}}</mat-label>
                <mat-select [placeholder]="'SelectCompany'|translate" [(value)]="selectedCompanyId" [disabled]="!selectedOrganizationId"
                    (selectionChange)="onCompanySelected()">
                    <mat-option *ngFor="let comp of comps" [value]="comp">{{comp}}</mat-option>
                </mat-select>
            </mat-form-field>

            <div class="container" *ngIf="this.context">
                <mat-icon class="icon">info</mat-icon>
                <div class="text-box">
                    <h3 class="context-title">{{'contextPath' |translate}} </h3>
                    <span class="context-subTitle">{{'thisWillContext' |translate}}
                        <b>{{selectedOrganizationId}}/{{selectedEnvironmentId}}/{{selectedCompanyId}}</b></span>
                </div>
            </div>

        </div>

    </mat-dialog-content>


</div>
<mat-dialog-actions align="end" style="margin-top: 24px; background-color: #F4F4F8; width: 109%; margin-left: -4.5%;">
    <button (click)="onCancel()" class="cancel" mat-button mat-dialog-close matTooltip="{{'CancelLower'|translate}}">
        {{'CancelLower'|translate}}
    </button>
    <button *ngIf="name && context" class="confirm" mat-button (click)="onSave()"
        matTooltip="{{'SaveChanges'|translate}}">
        {{'Confirm'|translate}}
    </button>
</mat-dialog-actions>