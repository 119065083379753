// tslint:disable: max-classes-per-file
// tslint:disable: no-namespace

import { Expose, Type } from 'class-transformer'
import { IsArray, IsBoolean, IsDate, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { SortDirection } from '../common'
import { DateType } from '../customDecorators'
import { Product, ProductImageCatalog } from '../misc/catalogStructure'
import { PlanogramWithId } from './planogram'
export * as CatalogStructure from '../misc/catalogStructure'


export class CatalogWithId extends ProductImageCatalog {
    @Expose() @IsString() id!: string
}

export class AddImageCatalogRequest {
    // @Expose() @IsString() id!: string
    @Expose() @IsString() mc1ProductId!: string
    @Expose() @IsString() name!: string
    @Expose() @IsOptional() @IsNumber() height?: number
    @Expose() @IsOptional() @IsNumber() width?: number
    @Expose() @IsOptional() @IsNumber() depth?: number
    @Expose() @IsString() file!: string
}
export class AddImageCatalogResponse extends ProductImageCatalog { }
export class UpdateImageCatalogResponse extends ProductImageCatalog { }

export class UpdateInfoCatalogFromPlanogramsCatalogRequest {
    @Expose() @IsString() id!: string
    @Expose() @IsString() mc1ProductId!: string
    @Expose() @IsString() name!: string
    @Expose() @IsNumber() height!: number
    @Expose() @IsNumber() width!: number
    @Expose() @IsOptional() @IsNumber() depth!: number
    @Expose() @IsString() file!: string
}

export class UpdateInfoCatalogFromPlanogramsRequest {
    @Expose() @Type(() => UpdateInfoCatalogFromPlanogramsCatalogRequest) @ValidateNested() catalog!: UpdateInfoCatalogFromPlanogramsCatalogRequest
    @Expose() @Type(() => PlanogramWithId) @ValidateNested() @IsArray() planograms!: PlanogramWithId[]
}

export class UpdatePrimaryImageCatalogRequest {
    @Expose() @IsString() productId!: string
    @Expose() @IsString() imageCatalogId!: string
    @Expose() @IsString() imageId!: string
}

export class UpdatePrimaryCatalogRequest {
    @Expose() @IsString() productId!: string
    @Expose() @IsString() imageCatalogId!: string
}

export class GetProductResponse {
    @Expose() @IsNumber() totalProducts!: number
    @Expose() @Type(() => Product) @ValidateNested() @IsArray() @IsOptional() products?: Product[]
    @Expose() @Type(() => ProductImageCatalog) @ValidateNested() @IsArray() @IsOptional() imageCatalogs?: ProductImageCatalog[]
}

export namespace SearchProducts {

    export class Item {
        @Expose() @IsString() productId!: string
        @Expose() @IsString() @IsOptional() thumbnailUrl?: string
        @Expose() @IsString() name!: string
        @Expose() @IsString() isCompetitors!: boolean
        @Expose() @IsBoolean() @IsOptional() inactive?: boolean
        @Expose() @IsString() @IsOptional() ean?: string
        @Expose() @IsString() @IsOptional() category?: string
        @Expose() @IsString() @IsOptional() brand?: string
        @Expose() @IsNumber() totalImageCatalogs!: number
        @Expose() @IsNumber() totalImages!: number
        @Expose() @DateType() @IsDate() updatedAt!: Date
        @Expose() @IsString() @IsOptional() family?: string
        @Expose() @Type(() => ProductImageCatalog) @IsOptional() @ValidateNested() @IsArray() imageCatalogs?: ProductImageCatalog[]
        @Expose() @IsNumber() qtUsedInPlanograms!: number
    }

    export class Response {
        @Expose() @IsNumber() totalProducts!: number
        @Expose() @Type(() => Item) @ValidateNested() @IsArray() items!: Item[]
    }
}

export class SearchProductsItemWithId extends SearchProducts.Item {
    @Expose() @IsString() id!: string
}

export namespace SearchProductsForPlanogramPlanning {

    export class ImageCatalog {
        @Expose() @IsString() id!: string
        @Expose() @IsString() thumbnailUrl!: string
        @Expose() @IsString() name!: string
        @Expose() @IsNumber() width!: number
        @Expose() @IsNumber() height!: number
        @Expose() @IsBoolean() @IsOptional() isTrained?: boolean
        @Expose() @IsBoolean() @IsOptional() filledHeightWidth?: boolean
        @Expose() @DateType() @IsDate() lastUpdate!: Date
    }

    export class Item {
        @Expose() @IsString() productId!: string
        @Expose() @IsString() name!: string
        @Expose() @IsString() category!: string
        @Expose() @IsString() @IsOptional() brand?: string
        @Expose() @IsBoolean() isCompetitors!: boolean
        @Expose() @Type(() => ImageCatalog) @ValidateNested() @IsArray() imageCatalogs!: ImageCatalog[]
        @Expose() @IsString() @IsOptional() ean?: string
    }

    export class Response {
        @Expose() @IsNumber() totalProducts!: number
        @Expose() @Type(() => Item) @ValidateNested() @IsArray() items!: Item[]
    }
}

export class SearchProductsResponse extends GetProductResponse { }

export class DeleteImageFromImageCatalogRequest {
    @Expose() @IsString() productId!: string
    @Expose() @IsString() imageCatalogId!: string
    @Expose() @IsString() fileLocation!: string
}

export class DeleteImageCatalogRequest {
    @Expose() @IsString() productId!: string
    @Expose() @IsString() imageCatalogId!: string
    @Expose() @IsBoolean() isPrimary!: boolean
}

export namespace ListCategoriesWithIds {
    export class Category {
        @Expose() @IsString() id!: string
        @Expose() @IsString() name!: string
    }

    export class Response {
        @Expose() @Type(() => Category) @ValidateNested() @IsArray() arr!: Category[]
    }
}

export namespace ListBrandsWithIds {
    export class Brand {
        @Expose() @IsString() id!: string
        @Expose() @IsString() name!: string
    }

    export class Response {
        @Expose() @Type(() => Brand) @ValidateNested() @IsArray() arr!: Brand[]
    }
}

export class UpdateImageCatalogRequest {
    @Expose() @IsString() productId!: string
    @Expose() @IsString() imageCatalogId!: string
    @Expose() @IsString() name!: string
    @Expose() @IsNumber() height!: number
    @Expose() @IsNumber() width!: number
    @Expose() @IsNumber() @IsOptional() depth?: number
    @Expose() @IsBoolean() @IsOptional() isActive?: boolean
}

export namespace UploadImagesToCatalog {
    export class Request {
        @Expose() @IsString() productId!: string
        @Expose() @IsString() imageCatalogId!: string
        @Expose() @IsString() fileUrl!: string
    }

    export class Response {
        @Expose() @IsString() filePath!: string
        @Expose() @IsString() thumbnailUrl!: string
        @Expose() @IsDate() date!: Date
    }
}

export namespace GetExistingProductsCatalogs {

    export class RequestItem {
        @Expose() @IsString() folder!: string
        @Expose() @Type(() => String) @IsArray() @IsString({ each: true }) subFolders!: string[]
    }

    export class Catalog {
        @Expose() @IsString() id!: string
        @Expose() @IsString() name!: string
    }

    export class ResponseItem {
        @Expose() @IsString() folder!: string
        @Expose() @Type(() => Catalog) @ValidateNested() @IsArray() catalogs!: Catalog[]
    }

    export class Request {
        @Expose() @Type(() => RequestItem) @ValidateNested() @IsArray() folders!: RequestItem[]
    }

    export class Response {
        @Expose() @Type(() => ResponseItem) @ValidateNested() @IsArray() folders!: ResponseItem[]
    }
}

export type ImageCatalogImageSource = 'user_feedback' | 'machine_learning' | 'webapp' | 'app'

export class ImageCatalogImage {
    @Expose() @IsBoolean() isPrimary!: boolean
    @Expose() @IsString() thumbnailPath!: string
    @Expose() @IsString() thumbnailLocation?: string
    @Expose() @IsString() fileLocation!: string
    @Expose() @IsString() source?: ImageCatalogImageSource
    @Expose() @IsDate() lastUpdate!: Date
    @Expose() @IsString() @IsOptional() approvalName?: string
}


export class ListCatalogImagesRequest {
    @Expose() @IsString() productId!: string
    @Expose() @IsString() catalogId!: string
    @Expose() @IsNumber() pageSize?: number
    @Expose() @IsString() direction?: SortDirection
    @Expose() @IsString() pageToken?: string
}

export class ListCatalogImagesResponse {
    @Expose() @Type(() => ImageCatalogImage) @IsArray() images!: ImageCatalogImage[]
    @Expose() @IsString() currentPageToken?: string
    @Expose() @IsString() nextPageToken?: string
    @Expose() @IsString() prevPageToken?: string
}

