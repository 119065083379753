import { StandaloneNavbarService } from './../../shared/services/standalone.navbar.service';
import { MemoryStorageService } from 'src/app/services/memory-storage.service';
import { Component, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';

import { Dal } from 'src/app/dal/dal';
import { AuthService } from 'src/app/shared/services/auth.service';
import { getCompanyType } from 'src/app/shared/services/companyType.service';
import { GetUserInfoResponse } from 'src/app/shared/api-structures/admin/user';

import packageInfo from '../../../../package.json';
import { UserManagementService } from 'src/app/shared/services/user-management.service';
import { Router } from '@angular/router';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import StandaloneNavbarItemModel from 'src/app/shared/models/standalone-navbar-item.model';
import { DashboardService } from '../../admin/services/dashboard.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-standalone-navbar',
  templateUrl: './standalone-navbar.component.html',
  styleUrls: ['./standalone-navbar.component.scss'],
})
export class StandaloneNavbarComponent extends AutoDestroy implements OnInit {
  isShowNav = false
  displaySidebar = true
  companyType = getCompanyType()
  showNavbar = true
  contractTraining: any;
  remainingTrainig: number;

  _logged: boolean = false

  public version: string = packageInfo.version

  userInfo: GetUserInfoResponse | null

  menuItems: StandaloneNavbarItemModel[] = []

  constructor(
    private dal: Dal,
    private authService: AuthService,
    private userManagementService: UserManagementService,
    private memoryStorageService: MemoryStorageService,
    private router: Router,
    private standaloneNavbarService: StandaloneNavbarService,
    private dashboardService: DashboardService,
    private languageService: LanguageService,
    private location: Location
  ) {
    super()
    this.subscriptions.push(router.events.subscribe(_ => this.isShowNav = false))
    this.dal.getUserInfo().then(userInfo => this.userInfo = userInfo)
    this.authService.$logged.subscribe(logged => this._logged = logged)
    this.userManagementService.getProfilePictureUrl().subscribe(url => {
      this.userInfo = { ...this.userInfo, profileImgUrl: url }
    })
  }

  async ngOnInit() {

    this.dal.getUserInfo().then(userInfo => this.userInfo = userInfo)
    this.loadMenuItems()
    this.getContractTraining()
    this.standaloneNavbarService.showNavbar.subscribe(v => {
      this.showNavbar = v
    })
  }

  async loadMenuItems() {
    const items = this.getDefaultMenuItems()

    const url = await this.getInternalDashboardUrl()

    const dashboardItems = this.getDashboardMenuItems()

    const { children } = dashboardItems

    const screens = this.extractDashboardScreensFromUrl(url)

    const dashboardScreens = children.filter(({ permission }) => screens.includes(permission));

    if (dashboardScreens.length > 0) {
      dashboardItems.children = dashboardScreens
      items.push(dashboardItems)
    }

    this.menuItems = items.sort((a, b) => a.order - b.order);
  }

  public navigateToPage(menuItem: StandaloneNavbarItemModel) {
    const { route, pageIdentifier } = menuItem
    this.router.navigate([route], { queryParams: { pageIdentifier } });
  }

  private getDefaultMenuItems(): StandaloneNavbarItemModel[] {
    return [
      {
        displayName: "Home",
        iconName: "home",

        route: "/home",
        permission: 'Home',
        children: [],
        order: 1,
        pageIdentifier: null,
      },
      {
        displayName: "Products",
        iconName: "list",
        route: "/products",
        permission: 'Products',
        children: [],
        order: 2,
        pageIdentifier: null,
      },
      {
        displayName: "Equipments",
        iconName: "view_list",
        route: "/equipments",
        permission: 'Planograms',
        children: [],
        order: 3,
        pageIdentifier: null,
      },
      {
        displayName: "Planograms",
        iconName: "grid_on",
        route: "/planograms",
        permission: 'Planograms',
        children: [],
        order: 4,
        pageIdentifier: null,
      },
      {
        displayName: "UnapprovedImages",
        iconName: "checklist_rtl",
        route: "/unapproved",
        permission: 'ImagesReview',
        children: [],
        order: 5,
        pageIdentifier: null,
      },
      {
        displayName: "MobileReports",
        iconName: "file_present",
        route: "/mobileReports",
        permission: 'Reports',
        children: [],
        order: 7,
        pageIdentifier: null,
      },
      {
        displayName: "ChatCPG",
        iconName: "comment",
        route: "/chatcpg",
        permission: 'ChatCpg',
        children: [],
        order: 8,
        pageIdentifier: null,
      }
    ];
  }

  private getDashboardMenuItems(): StandaloneNavbarItemModel {
    const items =
    {
      displayName: "Dashboards",
      iconName: "bar_chart",
      route: null,
      permission: 'Dashboard',
      children: [
        {
          displayName: "ComplianceMenu",
          iconName: "security",
          route: "/dashboard-compliance",
          permission: 'complienceKPI',
          children: [],
          order: 1,
          pageIdentifier: 'complienceKPI',
        },
        {
          displayName: "OverallAnalysisMenu",
          iconName: "analytics",
          route: "/dashboard-analysis",
          permission: 'overallAnalysis',
          children: [],
          order: 2,
          pageIdentifier: 'overallAnalysis',
        },
        {
          displayName: "AccuracyMenu",
          iconName: "check_circle",
          route: "/dashboard-accuracy",
          permission: 'accuracyIndicators',
          children: [],
          order: 3,
          pageIdentifier: 'accuracyIndicators',
        },
        {
          displayName: "PrecisionMenu",
          iconName: "adjust",
          route: "/dashboard-precision",
          permission: 'precisionIndicator',
          children: [],
          order: 4,
          pageIdentifier: 'precisionIndicator',
        },
        {
          displayName: "PriceMenu",
          iconName: "attach_money",
          route: "/dashboard-price",
          permission: 'priceAnalysis',
          children: [],
          order: 5,
          pageIdentifier: 'priceAnalysis',
        },
        {
          displayName: "UsageReportMenu",
          iconName: "data_usage",
          route: "/dashboard-usage-report",
          permission: 'usageReport',
          children: [],
          order: 6,
          pageIdentifier: 'usageReport',
        },
        {
          displayName: "HeatmapMenu",
          iconName: "whatshot",
          route: "/dashboard-heatmap",
          permission: 'heatmap',
          children: [],
          order: 7,
          pageIdentifier: 'heatmap',
        },
        {
          displayName: "MonitoringMenu",
          iconName: "supervised_user_circle",
          route: "/dashboard-monitoring",
          permission: 'monitoring',
          children: [],
          order: 8,
          pageIdentifier: 'monitoring',
        },
        {
          displayName: "AuditMenu",
          iconName: "assignment",
          route: "/dashboard-audit",
          permission: 'audit',
          children: [],
          order: 9,
          pageIdentifier: 'audit',
        },
        {
          displayName: "ReportsMenu",
          iconName: "summarize",
          route: "/dashboard-reports",
          permission: 'reports',
          children: [],
          order: 10,
          pageIdentifier: 'reports',
        },
      ],
      order: 6,
      pageIdentifier: null,
    }

    return items
  }

  async getInternalDashboardUrl() {
    const { url } = await this.dashboardService.getInternalDashboardUrl()
    const lang = this.languageService.getLanguage()
    return `${url}&lang=${lang}`
  }

  private extractDashboardScreensFromUrl(url: string): string[] {
    const [, queryString] = url.split('?')
    const parameters = new URLSearchParams(queryString)
    const screensParam = parameters.get('screens')
    const screens = screensParam.split(',');
    return screens
  }

  async logout() {
    this.standaloneNavbarService.setShowNavbar(false)
    await this.authService.logOut()
    window.location.reload()
  }

  getContractTraining() {
    this.memoryStorageService.contractTrainingObs.subscribe(v => {
      if (v) {
        this.contractTraining = v
        this.remainingTrainig = Math.abs(v.trainingCount - v.trainingQuota + v.singleTrainingSold);
      } else {
        this.remainingTrainig = 0;
      }
    })
  }

  redirectToAdmin() {
    this.standaloneNavbarService.setShowNavbar(false)
  }
}
