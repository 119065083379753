<div class="padding">
    <div class="title">
        {{'RegisteredOrganizations'|translate}}
    </div>
    <div style='padding-right: 15px;padding-bottom: 20px;padding-left: 5px;'>
        <button matTooltip="{{'Refresh'|translate}}" mat-mini-fab color="primary" (click)="refresh()">
            <mat-icon>refresh</mat-icon>
        </button>
        <span style='padding: 10px;'></span>

        <button mat-mini-fab matTooltip="{{ 'ADD' | translate }}" color="primary" (click)="add()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <organizations-list></organizations-list>
</div>