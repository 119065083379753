import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import validbarcode from "barcode-validator";
import { Dal } from 'src/app/dal/dal';
import { Observable, Subject } from 'rxjs';
import { ImageCatalogImage, ListBrandsWithIds, ListCategoriesWithIds } from 'src/app/shared/api-structures/misc/catalog';
import { Category } from 'src/app/shared/api-structures/misc/category';
import { Brand } from 'src/app/shared/api-structures/misc/brand';
import { AddProductRequest } from 'src/app/shared/api-structures/misc/product';
import { map, startWith } from 'rxjs/operators';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/shared/services/language.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { getCompanyType } from 'src/app/shared/services/companyType.service';
import { ConfirmDialogComponent } from '../../general/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/services/app.service';
import { ProductImageCatalog } from 'src/app/shared/api-structures/misc/catalogStructure';
import { ImagesCatalog } from 'src/app/shared/interfaces';
import JSZip from "jszip";
import axios from "axios";
import { saveAs } from "file-saver";
import { ListImagestoApproveFilter } from 'src/app/shared/api-structures/misc/toApprove';
import { ApiService } from 'src/app/shared/services/api.service';
import { OpenImageDialogComponent } from 'src/app/shared/components/open-image-dialog/open-image-dialog.component';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-add-edit-product',
  templateUrl: './add-edit-product.component.html',
  styleUrls: ['./add-edit-product.component.scss', 'product-catalogs.component.scss']
})
export class AddEditProductComponent implements OnInit {
  mode: 'edit' | 'new'
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  enableScreen = false
  thumbnailUrl: string
  productName: string
  productId: string
  imagesCatalogs: ProductImageCatalog[] = []
  isLoadingCategories: boolean
  isLoadingBrands: boolean
  showAddButton: boolean = false
  filteredCategoryItems: Observable<any[]>
  filteredBrandItems: Observable<any[]>
  product: AddProductRequest
  categories: ListCategoriesWithIds.Category[] = []
  brands: ListBrandsWithIds.Brand[] = []
  catalogSelected
  isShowAddCategoryDialog: boolean = false
  isShowAddBrandDialog: boolean = false
  _isCompetitorsCtrl: boolean
  _inactive: boolean
  companyType = getCompanyType()
  productForm = new FormGroup({
    inactive: new FormControl(),
    nameCtrl: new FormControl(),
    eanCtrl: new FormControl({ value: '', disabled: false }, [this.validateBarcode]),
    isCompetitorsCtrl: new FormControl(false),
    categoryCtrl: new FormControl(),
    brandCtrl: new FormControl(),
    widthCtrl: new FormControl(),
    heightCtrl: new FormControl(),
  })
  catalogForm = new FormGroup({
    catalogName: new FormControl(),
    catalogWidth: new FormControl(),
    catalogHeight: new FormControl()
  })
  isNewCatalog = false
  shouldShowRemoveCatalog = true
  showCatalogDimensions = true
  images: ImageCatalogImage[] = []
  uploadingFiles: {
    name: string,
    progress: number
  }[] = []
  private currentCatalogImagesRequestSubject: Subject<void> | null = null
  totalImages: number = 0
  private currentCountCatalogImagesRequestSubject: Subject<void> | null = null
  loadedCatalogs: boolean = false

  constructor(private dal: Dal,
    private utilitiesService: UtilitiesService,
    private languageService: LanguageService,
    private snackbarService: SnackbarService,
    private router: Router,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private appService: AppService,
    private apiService: ApiService
  ) {
    const urlParams = this.utilitiesService.getUrlParams(window.location.href);
    this.mode = urlParams['mode']
    this.productId = urlParams['product_id']
  }

  ngOnInit() {
    this.product = {
      name: '',
      isCompetitors: false,
      ean: '',
      category: {
        id: null,
        name: null
      },
      brand: {
        id: null,
        name: null
      },
      width: null,
      height: null
    }
    if (this.companyType === 'MC1') {
      this.productForm.controls.brandCtrl.disable()
      this.productForm.controls.categoryCtrl.disable()
      this.productForm.controls.eanCtrl.disable()
      this.productForm.controls.heightCtrl.disable()
      this.productForm.controls.widthCtrl.disable()
      this.productForm.controls.inactive.disable()
      this.productForm.controls.isCompetitorsCtrl.disable()
      this.productForm.controls.nameCtrl.disable()
    }
    this.loadFields().then(() => {
      if (this.mode === 'edit') {
        this.loadProduct()
      }
      this.enableScreen = true
    })
  }

  changeTab(index) {
    this.tabGroup.selectedIndex = index
    this.loadCatalogs()
    if (index === 0) {
      this.handleOneListCatalogImagesRequest()
    }
  }

  loadCatalogs() {
    if (this.tabGroup.selectedIndex === 1) {
      const { length: catalogsLength } = this.imagesCatalogs
      if (catalogsLength > 0) {
        const [firstCatalog] = this.imagesCatalogs
        this.selectCatalog(firstCatalog)
      } else if (this.loadedCatalogs) {
        this.isNewCatalog = true
      }
    }
  }

  async loadProduct() {
    const product = await this.dal.getProduct(this.productId)
    const userLanguage = this.languageService.getLanguage() || 'en'
    const collator = new Intl.Collator(userLanguage, { numeric: true, sensitivity: 'base' })
    this.imagesCatalogs = product.imageCatalogs.sort((a, b) => collator.compare(a.name, b.name))
    this.loadedCatalogs = true
    if (product.products.length === 0) {
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync('ErrorProductNotFound'))
      this.router.navigateByUrl('/products')
    }
    this.thumbnailUrl = product.products[0].thumbnailUrl ?? product.imageCatalogs[0]?.thumbnailUrl
    this.updateCatalogStar()
    this.productName = product.products[0].name
    this.productForm.controls.nameCtrl.setValue(product.products[0].name)
    this.productForm.controls.categoryCtrl.setValue(product.products[0].category)
    this.brands.map(b => {
      if (b.name === product.products[0].brandNames[0]) {
        this.productForm.controls.brandCtrl.setValue(b)
      }
    })
    this.categories.map(c => {
      if (c.name === product.products[0].categoriesNames[0]) {
        this.productForm.controls.categoryCtrl.setValue(c)
      }
    })
    this.productForm.controls.eanCtrl.setValue(product.products[0].ean ?? '')
    this.productForm.controls.isCompetitorsCtrl.setValue(product.products[0].isCompetitors)
    this._inactive = product.products[0].inactive
    this._isCompetitorsCtrl = product.products[0].isCompetitors
    this.productForm.controls.heightCtrl.setValue(product.products[0].height)
    this.productForm.controls.widthCtrl.setValue(product.products[0].width)
    this.productForm.controls.eanCtrl.setValue(product.products[0].ean)
  }

  async loadFields() {
    const companySettings = await this.dal.getCompanySettings()
    this.showCatalogDimensions = companySettings.showCatalogDimensions === undefined && this.companyType === 'MC1' ? true : companySettings.showCatalogDimensions
    await this.getCategories()
    this.filteredCategoryItems = this.productForm.get('categoryCtrl').valueChanges
      .pipe(
        startWith(''),
        map(item => this.filterCategoryItems(item || ''))
      )

    await this.getBrands()
    this.filteredBrandItems = this.productForm.get('brandCtrl').valueChanges
      .pipe(
        startWith(''),
        map(item => this.filterBrandItems(item || ''))
      )
  }

  validateBarcode(control: FormControl) {
    if (control?.value && !validbarcode(control.value)) {
      return { validateBarcode: true }
    }
    return null
  }

  async createCatalog() {
    this.catalogForm.reset()
    this.totalImages = 0
    this.images = []
    this.isNewCatalog = true
    this.enableScreen = true
  }

  private async handleOneListCatalogImagesRequest() {
    if (this.currentCatalogImagesRequestSubject) {
      this.currentCatalogImagesRequestSubject.next()
      this.currentCatalogImagesRequestSubject.complete();
    }
    this.currentCatalogImagesRequestSubject = new Subject<void>();
  }

  private async handleOneCountCatalogImagesRequest() {
    if (this.currentCountCatalogImagesRequestSubject) {
      this.currentCountCatalogImagesRequestSubject.next()
      this.currentCountCatalogImagesRequestSubject.complete();
    }
    this.currentCountCatalogImagesRequestSubject = new Subject<void>();
  }

  async selectCatalog(catalog) {
    this.images = []
    this.totalImages = 0
    this.handleOneListCatalogImagesRequest()
    this.handleOneCountCatalogImagesRequest()
    this.shouldShowRemoveCatalog = !catalog.isPrimary && this.productId !== 'noise'
    this.isNewCatalog = false
    this.catalogSelected = catalog.id
    this.catalogForm.controls.catalogName.setValue(catalog.name)
    this.catalogForm.controls.catalogWidth.setValue(catalog.width)
    this.catalogForm.controls.catalogHeight.setValue(catalog.height)
    try {
      const res = await this.dal.listCatalogImages({
        productId: this.productId,
        catalogId: catalog.id,
        pageSize: 1000
      }, this.currentCatalogImagesRequestSubject)
      if (res.images.length >= 1000) {
        this.totalImages = (await this.dal.countProductCatalogImages(this.productId, catalog.id, this.currentCountCatalogImagesRequestSubject)).countImages
      } else {
        this.totalImages = res.images.length
      }
      this.images = res.images
    } catch (error) {
      return
    }
  }

  async removeCatalog(catalog?: any, event?: MouseEvent) {
    if (event) event.stopPropagation()
    const catalogId = catalog !== undefined ? catalog.id : this.catalogSelected
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: this.languageService.translateSync('RemoveCatalog') }
    })

    dialogRef.afterClosed().subscribe(async confirmResult => {
      if (!confirmResult) {
        return
      }

      const unapprovedImages = this.checkUnapprovedImagesForThisCatalog(catalogId)
      if (await unapprovedImages === true) {
        this.snackbarService.openSnackBar(5000, this.languageService.translateSync('CatalogContainsUnapprovedImages'))
        return
      }

      const isCatalogAssociatedWithPlanogram = await this.dal.getPlanogramFromCatalog(catalogId, this.productId)

      if (isCatalogAssociatedWithPlanogram.totalPlanograms > 0) {
        let errorMessage = ''
        this.translateService.get('ImageCatalogCannotBeDeleted').subscribe(res => {
          errorMessage = res
        })

        this.snackbarService.openSnackBar(5000, errorMessage)
        return
      }

      await this.dal.deleteImageCatalog(this.productId, catalogId, catalog.isPrimary ?? false)
      if (catalog.isPrimary) this.thumbnailUrl = undefined
      this.imagesCatalogs = this.imagesCatalogs.filter(el => el.id !== catalogId)
      this.catalogSelected = undefined
      this.images = []
      this.totalImages = 0
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync('ImageCatalogDeletedSuccessfully'))
    })
  }

  downloadAllImages() {
    const zip = new JSZip();
    const productId = this.productId
    const fileArr = []
    this.images.map((i, index) => fileArr.push({ name: `${index}.png`, url: i.thumbnailPath }))
    const download = (item) => {
      return axios.get(item.url, { responseType: "blob" }).then((resp) => {
        zip.file(item.name, resp.data);
      });
    };
    const downloadAll = () => {
      const arrOfFiles = fileArr.map((item) => download(item));
      Promise.all(arrOfFiles)
        .then(() => {
          zip.generateAsync({ type: "blob" }).then(function (blob) {
            saveAs(blob, `${productId}.zip`);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    downloadAll()
  }

  async uploadImages(event: Event) {
    if (!(event.target instanceof HTMLInputElement)) {
      throw new Error('uploadImages event.target should be of type HTMLInputElement')
    }

    this.apiService.showLoading()

    const files = event.target.files

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      const uploadFunc = async () => {
        const uploadProgress = { name: file.name, progress: 0 }

        if (this.utilitiesService.validateImageType(file) == false) {
          return
        }

        this.uploadingFiles.push(uploadProgress)
        const fileUrl = await this.appService.uploadFileToStorageAndCompressIfNeedded(file, (progress) => uploadProgress.progress = progress, true)
        const fileValid = await this.utilitiesService.isFileUrlExist(fileUrl)

        if (fileValid === false) {
          this.uploadingFiles = this.uploadingFiles.filter(v => v !== uploadProgress)
          return
        }

        let response

        try {
          response = await this.dal.uploadImageCatalog(this.productId, this.catalogSelected, fileUrl)
          this.uploadingFiles = this.uploadingFiles.filter(v => v !== uploadProgress)
          this.images.unshift(response)
          this.totalImages++
          this.apiService.hideLoading()
        } catch (e) {
          this.apiService.hideLoading()
          this.uploadingFiles.pop()
          return
        }
      }

      uploadFunc()
    }
  }

  async uploadPrimaryImage(event: { target: { files: File[] } }) {
    const file: File = event.target.files[0]

    if (this.utilitiesService.validateImageType(file) == false) {
      return
    }

    const uploadProgress = { name: file.name, progress: 0 }
    this.uploadingFiles = [uploadProgress]
    const fileUrl = await this.appService.uploadFileToStorageAndCompressIfNeedded(file, (progress) => this.uploadingFiles[0].progress = progress)
    const resultFileCreation = await this.utilitiesService.isFileUrlExist(fileUrl)

    if (resultFileCreation === false) {
      this.uploadingFiles = this.uploadingFiles.filter(v => v !== uploadProgress)
      return
    }

    this.images.push({ fileLocation: fileUrl, isPrimary: true, thumbnailPath: fileUrl, lastUpdate: new Date() })
    this.totalImages++
    this.uploadingFiles = []
  }

  uploadPrimaryImageBtnClick() {
    document.getElementById("singleFile").click()
  }

  uploadImageBtnClick() {
    document.getElementById("multipleFiles").click()
  }

  async deleteImageFromCatalog(fileLocation, event: Event) {
    event.stopPropagation();
    await this.dal.deleteImageFromImageCatalog(this.productId, this.catalogSelected, fileLocation)
    this.images = this.images.filter(i => i.fileLocation !== fileLocation)
    this.totalImages--
  }

  favoriteCatalog(catalogId, event?: MouseEvent) {
    if (event) event.stopPropagation()
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: this.languageService.translateSync('AreYouSureChangePrimaryCatalog') }
    })

    dialogRef.afterClosed().subscribe(async confirmResult => {
      if (!confirmResult) {
        return
      }
      const catalog = this.imagesCatalogs.filter(ic => ic.id === catalogId)
      await this.dal.updatePrimaryCatalog(this.productId, catalog[0].id)
      this.thumbnailUrl = catalog[0].thumbnailUrl
      this.updateCatalogStar()
    })
  }

  updateCatalogStar() {
    this.imagesCatalogs.map(ic => {
      ic.isPrimary = false
      if (ic.thumbnailUrl === this.thumbnailUrl) {
        ic.isPrimary = true
      }
    })
  }

  favoriteImage(imageId: string, imgUrl, event: Event) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: this.languageService.translateSync('ClickingYesAllPlanogramsWillBeChangedWithTheSelectedImage') }
    })

    dialogRef.afterClosed().subscribe(async confirmResult => {
      if (!confirmResult) {
        return
      }

      const isCatalogAssociatedWithPlanogram = await this.dal.getPlanogramFromCatalog(this.catalogSelected, this.productId)

      if (isCatalogAssociatedWithPlanogram.totalPlanograms > 0) {
        await this.dal.updateImageCatalogFromPlanograms(isCatalogAssociatedWithPlanogram.planograms, this.catalogSelected, imgUrl)
      }
      await this.dal.updatePrimaryImageCatalog(this.productId, this.catalogSelected, imageId)
      this.setPrimaryImageLocaly(imgUrl, this.catalogSelected)
    })
  }

  async setPrimaryImageLocaly(imgUrl, catalogId) {
    this.images.map(i => { i.isPrimary = false })
    this.images.map(i => {
      if (i.thumbnailPath === imgUrl) {
        i.isPrimary = true
      }
    })
    this.imagesCatalogs.map(i => {
      if (i.id === catalogId) {
        i.thumbnailUrl = imgUrl
        if (i.isPrimary) {
          this.thumbnailUrl = imgUrl
          this.favoriteCatalog(catalogId)
        }
      }
    })
  }

  async downloadImageFromCatalog(imageSrc, event: Event) {
    event.stopPropagation();
    const image = await fetch(imageSrc)
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
    const link = document.createElement('a')
    link.href = imageURL
    link.download = this.product.name.replace('.', '')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  async getCategories() {
    this.isLoadingCategories = true
    const categoriesRes = await this.dal.getCategoriesWithIds()
    const unique = categoriesRes.filter((obj, index) => {
      return index === categoriesRes.findIndex(o => obj.name === o.name)
    })
    this.categories = unique
    this.isLoadingCategories = false
  }

  async getBrands() {
    this.isLoadingBrands = true
    const brandsRes = await this.dal.getBrandsWithIds()
    const unique = brandsRes.filter((obj, index) => {
      return index === brandsRes.findIndex(o => obj.name === o.name)
    })
    this.brands = unique
    this.isLoadingBrands = false
  }

  filterCategoryItems(item: string | Category) {
    const name = typeof item === 'string' ? item : item.name
    const results = this.categories?.filter(category =>
      category.name?.toLowerCase().startsWith(name?.toLowerCase())
    );
    this.showAddButton = this.categories.filter(i => i?.name == name).length === 0
    return results
  }

  categoryOptionSelected(option) {
    if ('name' in option.value) {
      this.product.category.id = option.value.id
      this.product.category.name = option.value.name
    }
  }

  brandOptionSelected(option) {
    if ('name' in option.value) {
      this.product.brand.id = option.value.id
      this.product.brand.name = option.value.name
    }
  }

  dropDownCategoryOptions() {
    const value = this.productForm.get('categoryCtrl').value
    this.productForm.get('categoryCtrl').setValue(value)
  }

  dropDownBrandOptions() {
    const value = this.productForm.get('brandCtrl').value
    this.productForm.get('brandCtrl').setValue(value)
  }

  filterBrandItems(item: string | Brand) {
    const name = typeof item === 'string' ? item : item.name
    const results = this.brands?.filter(brand =>
      brand.name?.toLowerCase().startsWith(name?.toLowerCase())
    );
    this.showAddButton = this.brands.filter(i => i.name == name).length === 0
    return results
  }

  async addNewCategory() {
    this.isShowAddCategoryDialog = false
    const option = this.productForm.get('categoryCtrl').value
    if (!this.categories.some(entry => entry.name === option)) {
      const categoryRes = await this.dal.addCategory({ name: option })
      const index = this.categories.push(categoryRes) - 1
      this.productForm.get('categoryCtrl').setValue(this.categories[index])
      this.product.category.id = categoryRes.id
      this.product.category.name = categoryRes.name
    }
  }

  async addNewBrand() {
    this.isShowAddBrandDialog = false
    const option = this.productForm.get('brandCtrl').value
    if (!this.brands.some(entry => entry.name === option)) {
      const brandRes = await this.dal.addBrand({ name: option })
      const index = this.brands.push(brandRes) - 1
      this.productForm.get('brandCtrl').setValue(this.brands[index])
      this.product.brand.id = brandRes.id
      this.product.brand.name = brandRes.name
    }
  }

  displayFn(category: Category): string {
    return category && category.name ? category.name : ''
  }

  displayFn2(brand: Brand): string {
    return brand && brand.name ? brand.name : ''
  }

  async updateCatalog() {
    if (this.catalogForm.controls.catalogName.value === undefined || this.catalogForm.controls.catalogName.value === null) {
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync('CatalogNameMandatory'))
      return
    }
    if (this.images.length === 0) {
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync('ToSaveACatalogMustHaveAtLeastOneImage'))
      return
    }
    if (this.isNewCatalog) {
      const catalogRes: ImagesCatalog = await this.dal.addImagesCatalog(this.productId,
        this.catalogForm.controls.catalogName.value,
        this.catalogForm.controls.catalogHeight.value,
        this.catalogForm.controls.catalogWidth.value,
        this.images[0].fileLocation,
        0
      )
      const primary = this.imagesCatalogs.length === 0
      this.imagesCatalogs.push(
        {
          id: catalogRes.id,
          name: catalogRes.name,
          height: catalogRes.height,
          width: catalogRes.width,
          mc1ProductId: catalogRes.mc1ProductId,
          thumbnailUrl: catalogRes.thumbnailUrl,
          lastUpdate: new Date(),
          depth: 0,
          isActive: true,
          isPrimary: primary
        }
      )
      if (primary) {
        this.thumbnailUrl = catalogRes.thumbnailUrl
      }
      this.isNewCatalog = false
      this.catalogSelected = catalogRes.id

      const selectedCatalogItem = this.imagesCatalogs.find(({ id }) => id === this.catalogSelected)

      this.selectCatalog(selectedCatalogItem)

      return
    }
    const isCatalogAssociatedWithPlanogram = await this.dal.getPlanogramFromCatalog(this.catalogSelected, this.productId)
    if (isCatalogAssociatedWithPlanogram.totalPlanograms > 0 && this.catalogForm.controls.catalogName.value) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: { message: this.languageService.translateSync('AllPlanogramsThatContainsThisCatalogMustBeCreatedAgain') }
      })

      dialogRef.afterClosed().subscribe(async confirmResult => {
        if (!confirmResult) {
          return
        }

        const catalog = {
          mc1ProductId: this.productId,
          id: this.catalogSelected,
          name: this.catalogForm.controls.catalogName.value,
          height: this.catalogForm.controls.catalogHeight.value,
          width: this.catalogForm.controls.catalogWidth.value,
          file: ''
        }

        await this.dal.updateInfoCatalogFromPlanograms(isCatalogAssociatedWithPlanogram.planograms, catalog)
      })
    }
    await this.dal.updateImageCatalog(this.productId,
      this.catalogSelected,
      this.catalogForm.controls.catalogName.value,
      this.catalogForm.controls.catalogHeight.value,
      this.catalogForm.controls.catalogWidth.value,
      0,
      true
    )
    this.imagesCatalogs.map(ic => {
      if (ic.id === this.catalogSelected) {
        ic.name = this.catalogForm.controls.catalogName.value
        ic.width = this.catalogForm.controls.catalogWidth.value
        ic.height = this.catalogForm.controls.catalogHeight.value
      }
    })
    this.snackbarService.openSnackBar(2000, this.languageService.translateSync('ImageCatalogSavedSuccessfully'))
  }

  async save() {
    if (this.tabGroup.selectedIndex === 0) {
      if (this.companyType === 'MC1') {
        return
      }
      if (!this.productForm.valid) {
        this.utilitiesService.validateAllFormFields(this.productForm)
        return
      }
      if (this.mode === 'edit') {
        this.dal.editProduct({
          id: this.productId,
          brand: this.productForm.controls.brandCtrl.value,
          isCompetitors: this._isCompetitorsCtrl === null ? false : this._isCompetitorsCtrl,
          category: this.productForm.controls.categoryCtrl.value,
          height: this.productForm.controls.heightCtrl.value,
          width: this.productForm.controls.widthCtrl.value,
          name: this.productForm.controls.nameCtrl.value,
          ean: this.productForm.controls.eanCtrl.value === '' ? undefined : this.productForm.controls.eanCtrl.value,
          inactive: this.productForm.controls.inactive.value === null ? false : this.productForm.controls.inactive.value
        }).then(() => {
          this.snackbarService.openSnackBar(3000, this.languageService.translateSync('ProductSavedSuccessfully'))
          this.changeTab(1)
        })
        return
      }
      await this.dal.addProduct({
        brand: this.productForm.controls.brandCtrl.value,
        isCompetitors: (this._isCompetitorsCtrl === null || this._isCompetitorsCtrl === undefined) ? false : this._isCompetitorsCtrl,
        category: this.productForm.controls.categoryCtrl.value,
        height: this.productForm.controls.heightCtrl.value,
        width: this.productForm.controls.widthCtrl.value,
        name: this.productForm.controls.nameCtrl.value,
        ean: this.productForm.controls.eanCtrl.value === '' ? undefined : this.productForm.controls.eanCtrl.value,
        inactive: (this._inactive === null || this._inactive === undefined) ? false : this._inactive
      }).then((res) => {
        this.productId = res.id
        this.productName = res.name
        this.snackbarService.openSnackBar(3000, this.languageService.translateSync('ProductSavedSuccessfully'))
        this.mode = 'edit'
        this.isNewCatalog = true
        this.changeTab(1)
      })
    } else {
      await this.updateCatalog()
    }
  }

  async checkUnapprovedImagesForThisCatalog(catalogId: string) {

    const objFilter: ListImagestoApproveFilter = {
      dateStart: null,
      dateEnd: null,
      userIds: [],
      origin: null,
      methodRecognition: null,
    }
    const res = await this.dal.searchImagesToApprove({
      productId: this.productId,
      imageCatalogId: catalogId,
      pageSize: 50,
      filterBy: undefined,
      newFilters: objFilter,
      sortDirection: 'desc',
      pageToken: undefined
    })
    if (res.items.length === 0) {
      return false
    }
    else {
      return true
    }
  }

  openImage(thumbnailPath: string) {
    if (!thumbnailPath)
      return

    const config = {
      data: {
        imageUrl: thumbnailPath
      }
    }

    this.dialog.open(OpenImageDialogComponent, config)
  }

}
