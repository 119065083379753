<div #planogramBuilder class="planogramBuilder"
    [style]="{height:pixelHeight+'px', width:pixelWidth+'px', background: equipment?.backgroundColor}">
    <div *ngFor="let shelf of shelves" [id]="shelf.id" [style]="shelf.style" class="item area"
        [ngClass]="{selected: shelf.id === selectedAreaId}" (click)="selectAreaId(shelf.area)">
    </div>
    <div *ngFor="let item of items" [title]="item?.data?.name || ''" matTooltipPosition="above" [id]="item.id"
        style="border: solid 1px #cdcdcd" [style]="item.style" class="item">
        <div *ngIf="item.text" [ngClass]="item?.data?.verticalText ? 'vertical-text item-text' : 'item-text'">{{item.text}}</div>
    </div>


    <div *ngIf="shadowItem" id="shadowItem" [style]="shadowItem?.style" class="item shadowItem"></div>
</div>
<div *ngIf="isTempItem" [id]="draggedItem?.id" style="border: solid 1px #cdcdcd" [ngStyle]="{
        'width': draggedItem?.style?.width,
        'height': draggedItem?.style?.height,
        'left': absoluteMouse[0] - draggedItem?.style?.width.replace('px','')/dragData.quantity/2+'px',
        'top': absoluteMouse[1] - draggedItem?.style?.height.replace('px','')/dragData.quantity/2+'px'
}" class="item temp-item">
    <div class="item-multi" *ngFor="let idx of [].constructor(dragData.quantity); index as i" [ngStyle]="{
        'background-color': draggedItem?.style?.backgroundColor, 
        'background-image':draggedItem?.style?.backgroundImage,
        'width': (100/dragData.quantity)+'%',
        'height': '100%',
        'left': (100/dragData.quantity)*i+'%',
        'top': '0%'
        }"></div>
</div>
<!-- for testing -->
<!-- <button (click)="createPlanogramImage()">canvas</button>
<div id="canvas-demo"></div> -->
