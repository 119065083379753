import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ListOrganizationsRequest } from 'src/app/shared/api-structures/super-admin/organization';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { OrganizationsService } from '../../services/organizations.service';
import { CreateUpdateOrganizationComponent } from './create-update-organization/create-update-organization.component';


@Component({
  selector: 'organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent extends AutoDestroy implements AfterViewInit {

  listUsersRequest: ListOrganizationsRequest

  constructor(private organizationsService: OrganizationsService, private dialog: MatDialog) {
    super()
    this.listUsersRequest = { currentPage: 0, query: '', pageSize: 25 }
  }

  ngAfterViewInit() {
    this.organizationsService.loadOrganizations(this.listUsersRequest)
  }

  refresh() {
    this.organizationsService.loadOrganizations(this.listUsersRequest)
  }

  add() {
    const dialogRef = this.dialog.open(CreateUpdateOrganizationComponent, { width: '40vw' })
    dialogRef.afterClosed().subscribe(v => {
      if (v && v.isOrganizationUpdateOrCreated) {
        this.refresh()
      }
    })
  }
}
