import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GetUserInfoResponse } from '../../api-structures/admin/user';
import StandaloneNavbarItemModel from '../../models/standalone-navbar-item.model';
import packageInfo from '../../../../../package.json';

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent {

  @Input() isShowNav: boolean;
  @Input() userInfo: GetUserInfoResponse;
  @Input() menuItems: StandaloneNavbarItemModel[];

  @Output() isShowNavChange = new EventEmitter<boolean>();
  
  public version: string = packageInfo.version;

  constructor(private router: Router) { }


  navigateToPage(menuItem: StandaloneNavbarItemModel) {
    const { route, pageIdentifier } = menuItem;
    this.router.navigate([route], { queryParams: { pageIdentifier } });
  }

}
