<div class="mat-elevation-z8">
  <div class="pre-table-header">
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup.enter)="applyFilter()" [(ngModel)]="filter" #input>
    </mat-form-field>
  </div>
  <table mat-table class="full-width-table" matSort [dataSource]="dataSource" multiTemplateDataRows>

    <ng-container matColumnDef="organizationId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'OrganizationId'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Name'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.name}}</td>
    </ng-container>

    <ng-container matColumnDef="companies">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Companies'|translate}}</th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.companies">{{row.companies}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'UpdatedAt'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.lastUpdate | date : 'dd/MM/yyyy HH:mm' }}</td>
    </ng-container>

    <ng-container matColumnDef="companiesIcon">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let row">
        <button matTooltip="{{'Companies'|translate}}" mat-button [routerLink]="['companies']"
          [queryParams]="{ organization_id : row.id }">
          <mat-icon style="color:#919191;transform: scale(0.8);">business</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="settings">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let row">
        <button matTooltip="{{'Settings'|translate}}" mat-button (click)="popupSettings(row.id,row.name)">
          <mat-icon style="color:#919191;transform: scale(0.8);">settings</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="editIcon">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let row">
        <button matTooltip="{{'Edit'|translate}}" mat-button (click)="updateOrganization(row.id,row.name)">
          <mat-icon fontSet="fa" fontIcon="fa-edit" style="color:#919191"></mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="deleteIcon">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let row">
        <button matTooltip="{{'Delete'|translate}}" mat-button (click)="ondeleteClick(row.id)">
          <mat-icon style="color:#919191;transform: scale(0.9);">delete_outline</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"> </tr>
  </table>

  <mat-paginator [length]="totalOrganizations" [pageSizeOptions]="[5, 10, 25, 100]" (page)="applyFilter()">
  </mat-paginator>
</div>